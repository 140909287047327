import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Input } from './form-elements/input';
import { NetlifyForm } from './form-elements/netlify-form';
import { Select } from './form-elements/select';

interface FormData {
  full_name: string;
  email_address: string;
  residential_address: string;
  postal_address: string;
  date_of_birth: string;
  contact_number: string;
  membership_number: string;
  what_kind_of_communications_would_you_like_to_receive: string;
}

function MembershipForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur', reValidateMode: 'onChange' });
  return (
    <NetlifyForm
      name="membership_form"
      handleSubmit={handleSubmit}
      className="w-full px-4 py-6 mx-auto mt-5 bg-white max-w-prose"
    >
      <h2 className="mt-5 text-3xl font-semibold tracking-wider uppercase text-blue-dark">
        Update Your Details
      </h2>
      <div className="grid grid-cols-1 gap-6 mt-6">
        <Input
          label="Full name"
          {...register('full_name', { required: true })}
          errors={errors}
        />
        <Input
          label="Email address"
          type="email"
          {...register('email_address', { required: true })}
          errors={errors}
        />
        <Input
          label="Date of Birth"
          {...register('date_of_birth', { required: true })}
          errors={errors}
        />
        <Input
          label="Residential address"
          {...register('residential_address', { required: true })}
          errors={errors}
        />
        <Input
          label="Postal address"
          {...register('postal_address', { required: true })}
          errors={errors}
        />
        <Input
          label="Contact number"
          type="tel"
          {...register('contact_number', { required: true })}
          errors={errors}
        />
        <Input
          label="Membership number"
          {...register('membership_number', { required: true })}
          errors={errors}
        />
        <Select
          label="What Kind of Communications Would You Like To Receive?"
          options={[
            {
              value: 'general_newsletter',
              label: 'General Newsletter',
            },
            {
              value: 'club_business_meeting_notices_annual_reports',
              label: 'Club Business - Meeting Notices & Annual Reports',
            },
            {
              value: 'marketing_promotional_information',
              label: 'Marketing & Promotional Information',
            },
          ]}
          {...register(
            'what_kind_of_communications_would_you_like_to_receive',
            { required: true }
          )}
          errors={errors}
        />
        <div className="grid mt-4 gap-y-5 gap-x-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
          <a
            href="/terms-conditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center bg-white border whitespace-nowrap button text-blue-dark border-blue-dark hover:text-white"
          >
            Read Terms and Conditions
          </a>
          <button type="submit" className="text-center button min-w-[14rem]">
            Submit
          </button>
        </div>
      </div>
    </NetlifyForm>
  );
}

export { MembershipForm };
