import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type SelectProps = {
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  required?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      description,
      descriptionId,
      errors,
      label,
      name,
      options,
      required,
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div className="flex flex-wrap justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-semibold tracking-wider uppercase"
        >
          {label}
        </label>
        {description ? (
          <span id={descriptionId} className="text-sm text-gray-500">
            {description}
          </span>
        ) : null}
        <div className="w-full mt-1">
          <select
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            required={required}
            className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-dark pb-0"
            {...rest}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </div>
    );
  }
);

export { Select };
