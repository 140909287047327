import { DialogContent, DialogOverlay } from '@reach/dialog';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { HiX } from 'react-icons/hi';

import {
  BackgroundTexture,
  BusinessCardIcon,
  HeadingWithIcon,
  Layout,
  MoneyBagIcon,
  SEO,
  SideBySide,
} from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';
import { MembershipBenefitsTable } from '../components/membership-benefits-table';
import { MembershipForm } from '../components/membership-form';
import { SideImage } from '../components/side-image';
import { MembershipIcon } from '../components/vectors/membership';
import { useGraphQL } from '../hooks';

function MembershipPage(): React.ReactElement {
  const { pcbRewards1, pcbRewards2 } = useGraphQL();
  return (
    <Layout>
      <SEO title="Membership" />
      {/* <MembershipRenewal /> */}
      <div className="relative w-full px-4 mx-auto mt-12 max-w-screen-2xl sm:px-6 lg:px-8">
        <GatsbyImage
          image={pcbRewards1.childImageSharp.gatsbyImageData}
          className="flex-1"
          alt="AGM"
          objectFit="contain"
        />
      </div>
      <div className="relative w-full px-4 mx-auto mt-12 max-w-screen-2xl sm:px-6 lg:px-8">
        <GatsbyImage
          image={pcbRewards2.childImageSharp.gatsbyImageData}
          className="flex-1"
          alt="AGM2"
          objectFit="contain"
        />
      </div>
      <MembershipSignUp />
      <MembershipBenefits />
      {/* <PointsPlus />
      <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <GatsbyImage
          image={agmNotice.childImageSharp.gatsbyImageData}
          className="flex-1"
          alt="AGM"
          objectFit="contain"
        />
      </div>
      <PointsPlusBenefits /> */}
      <div className="-mt-24 xs:-mt-0" />
      <ContactFormWithMap background="grunge" />
    </Layout>
  );
}

function MembershipRenewal(): React.ReactElement {
  return (
    <div id="membership_renewal" className="scroll-margin-top-24">
      <div className="bg-rust">
        <div className="px-3 py-3 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center flex-1 w-0">
              <span className="flex pb-2">
                <MembershipIcon className="w-full max-w-[6rem] max-h-[6rem] text-white" />
              </span>
              <p className="ml-3 text-lg font-medium tracking-wider text-white uppercase">
                Need to renew your membership?
              </p>
            </div>
            <div aria-hidden className="flex-shrink-0 w-6 h-6">
              {/* This element tricks the browser into not collapsing on mobile */}
            </div>
            <div className="flex-shrink-0 w-full mt-2 sm:mt-0 sm:w-auto">
              <a
                href="https://portcitybowlingclub.wufoo.com/forms/port-city-bowling-club-membership-renewal/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium tracking-wider text-white uppercase border border-white shadow-sm bg-rust"
              >
                Renew Membership Here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MembershipSignUp(): React.ReactElement {
  return (
    <div id="membership_signup" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <article className="grid gap-8 lg:grid-cols-5">
          <div className="flex flex-col justify-center lg:col-span-2">
            <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
              <HeadingWithIcon>
                <HeadingWithIcon.Heading>Membership</HeadingWithIcon.Heading>
                <HeadingWithIcon.Icon hidden>
                  <BusinessCardIcon />
                </HeadingWithIcon.Icon>
              </HeadingWithIcon>
              <div className="mt-5 prose">
                <p>
                  Belonging to Port City Bowling Club is more than a membership
                  - you become part of a community of fun, fabulous like-minded
                  people who enjoy socialising with friends, great food and the
                  best of sport.
                </p>
                <p>
                  It’s easy to belong to the best club in Port Macquarie and
                  affordable too! - Only for $12 per year or $30 for three
                  years! Join now at the club or renew your membership below.
                </p>
                <p>
                  <span className="text-lg font-semibold uppercase">
                    1 Year - $12 or 3 Years - $30{' '}
                  </span>
                </p>
                <p>Join today to receive:</p>
                <ul>
                  <li>
                    A chance to play the birthday wheel once a day for their
                    calendar birthday month for a chance to win a birthday prize
                  </li>
                  <li>Members discounts at the Drift bar·cafe·restaurant</li>
                  <li>
                    Members promotions through our Port City Rewards kiosk
                  </li>
                </ul>
                <p>
                  Print the membership form below, complete the details and see
                  our friendly reception staff next time you’re at Port City.
                  Your application will be processed on the spot.
                </p>
                <p>Make sure you bring along your photo ID!</p>
              </div>
              <p className="mt-5">
                <a
                  href="/membership-form-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center text-center button"
                >
                  Download Membership Form
                </a>
              </p>
            </div>
          </div>
          <div className="my-auto lg:col-span-3">
            <MembershipForm />
          </div>
        </article>
      </div>
    </div>
  );
}

function MembershipBenefits(): React.ReactElement {
  return (
    <></>
    // <div
    //   id="membership_benfits"
    //   className="relative py-12 mt-12 scroll-margin-top-24"
    // >
    //   {/* <div className="relative w-full px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
    //     <h2 className="heading-2 heading-accent">Pulse Rewards Benefits</h2>
    //     <MembershipBenefitsTable />
    //   </div> */}
    // </div>
  );
}

function PointsPlus(): React.ReactElement {
  const { pointsPlus } = useGraphQL();
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <div id="points_plus" className="py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <SideImage image={pointsPlus.childImageSharp.gatsbyImageData} />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <h2 className="heading-2 heading-accent">
                Points
                <br />
                Plus
              </h2>
              <MoneyBagIcon className="hidden w-20 text-blue-dark xs:block" />
            </HeadingWithIcon>
            <h3 className="mt-5 heading-3">PointsPlus Prepaid EFTPOS Card</h3>
            <div className="mt-5 prose">
              <p>
                The Port City Bowling Club Prepaid PointsPlus EFTPOS Card has
                been created to give members the freedom to spend reward points
                earned with Port City Bowling Club at any merchant in Australia
                that accepts EFTPOS, subject to merchant discretion. You can
                also transfer promotional wins onto the card, as well as load
                your own funds onto the card for additional or higher value
                purchases.
              </p>
              <p>
                <span className="font-bold">Disclaimer:</span> Gobsmacked
                Loyalty Pty Ltd ABN 60 098 218 216 (AFSL 444609) is the issuer
                of the card. The PDS and TMD is available and can be obtained
                online at www.portcity.com.au. You should consider the PDS and
                TMD in deciding whether or not to acquire or keep the card. Port
                City Bowling Club is responsible for the Points Plus Rewards
                program and promotions and the conversion of reward points to
                monetary value. Refer to the Port City Bowling Club reward
                promotions and program terms and conditions.
              </p>
              <div className="italic prose-sm prose">
                <h4 className="!font-semibold not-italic">
                  Terms and conditions and fees apply to the use of your card.
                  Please see the following documents for further information:
                </h4>
                <a
                  href="/pds-supplementary.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center text-center"
                >
                  1. Download Product Disclosure Statement
                </a>
                <a
                  href="/pds-notice.docx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center mt-2 text-center"
                >
                  2. Download Points Plus Eftpos Card Notice
                </a>
              </div>
              <p className="mt-4">
                <button
                  type="button"
                  onClick={open}
                  className="inline-flex items-center justify-center text-center button"
                >
                  Activate your points plus here
                </button>
              </p>
            </div>
          </div>
        </div>
      </SideBySide>
      <DialogOverlay
        isOpen={showDialog}
        onDismiss={close}
        className="fixed inset-0 z-40 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-30" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          />
          <DialogContent
            style={{ margin: 0 }}
            className="inline-block px-4 pt-5 pb-4 m-0 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                onClick={close}
                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <HiX aria-hidden className="w-6 h-6" />
              </button>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h2 className="text-center heading-3">Points Plus links</h2>
              <div className="mt-5 text-center">
                {[
                  // {
                  //   title: 'Points Plus PDS',
                  //   link:
                  //     'https://www.dropbox.com/s/z3988859jt33yon/GSL%20PCB%20PDS%2007.pdf?dl=0',
                  // },
                  // {
                  //   title: 'Points Plus FSG',
                  //   link:
                  //     'https://portcity.com.au/wp-content/uploads/2017/06/GSL-PCB-FSG-08.pdf',
                  // },
                  {
                    title: 'Points Plus TMD',
                    link: '/points-plus-tmd.pdf',
                  },
                  {
                    title: 'Activate Your Points Plus Card',
                    link:
                      'https://prepaidcards.indue.com.au/pointsplus/Activation/verifycard.aspx#_blank',
                  },
                  {
                    title: 'Points Plus Card Holder Click Here',
                    link:
                      'https://prepaidcards.indue.com.au/pointsplus/#_blank',
                  },
                ].map(({ title, link }) => (
                  <p key={title}>
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block w-full max-w-sm px-4 py-2 mt-5 text-xs font-medium uppercase transition duration-150 ease-in-out bg-white border hover:bg-blue-dark hover:text-white sm:text-base border-blue-dark"
                    >
                      {title}
                    </a>
                  </p>
                ))}
              </div>
            </div>
          </DialogContent>
        </div>
      </DialogOverlay>
    </div>
  );
}

function PointsPlusBenefits(): React.ReactElement {
  const { landingFunction } = useGraphQL();
  return (
    <div className="pt-0 pb-12 xs:py-12">
      <SideBySide
        sideComponent={
          <div className="flex flex-col justify-center flex-1 p-4 pt-8 bg-blue-light">
            <div className="w-full py-8 mx-auto max-w-prose">
              <h2 className="heading-2 heading-accent">Points Plus Benefits</h2>
              <div className="mt-5 prose prose-on-dark">
                <div>
                  <p>
                    There are no credit checks required, and the card is not
                    linked to your personal bank accounts. It’s a totally
                    prepaid solution.
                  </p>
                  <p>
                    As a cardholder you’ll also be eligible to gain access to
                    special promotions and activities at Port City Bowling Club
                    specifically designed for the PointsPlus Rewards Program.
                    (Terms and Conditions apply).
                  </p>
                  <ul className="hidden xs:block" style={{ columnCount: 2 }}>
                    <li>
                      Use your card at 325,000 retailers across Australia, at
                      merchant discretion
                    </li>
                    <li>
                      Convert your reward points you earn with Port City Bowling
                      Club to a dollar value
                    </li>
                    <li>
                      Keep your converted reward points, promotional wins, and
                      own funds all on one card!
                    </li>
                    <li>Pay your bills</li>
                    <li>Eat out at restaurants</li>
                    <li>Save for a holiday</li>
                    <li>Exclusive access to POINTSPlus Rewards promotions</li>
                    <li>Reduces the need to carry cash</li>
                    <li>Choose where you want to shop</li>
                  </ul>
                  <ul className="block xs:hidden" style={{ columnCount: 1 }}>
                    <li>
                      Use your card at 325,000 retailers across Australia, at
                      merchant discretion
                    </li>
                    <li>
                      Convert your reward points you earn with Port City Bowling
                      Club to a dollar value
                    </li>
                    <li>
                      Keep your converted reward points, promotional wins, and
                      own funds all on one card!
                    </li>
                    <li>Pay your bills</li>
                    <li>Eat out at restaurants</li>
                    <li>Save for a holiday</li>
                    <li>Exclusive access to POINTSPlus Rewards promotions</li>
                    <li>Reduces the need to carry cash</li>
                    <li>Choose where you want to shop</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <GatsbyImage
          image={landingFunction.childImageSharp.gatsbyImageData}
          className="flex-1 h-full"
          alt=""
        />
      </SideBySide>
    </div>
  );
}

export { MembershipPage as default };
